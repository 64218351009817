
import { Delete, Edit, View, Search } from "@element-plus/icons-vue";
// import { search, del } from "../../api/frag.js";
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";

import { client } from "@/client";
export default defineComponent({
  name: "frag-index",
  setup() {
    return {
      Edit,
      Delete,
      View,
      Search,
    };
  },
  data: () => {
    return {
      keywords: "",
      tableData: [] as any[],
      multipleSelection: [] as any[],
      count: 0,
      cur: 1,
    };
  },
  computed: {},
  created() {
    this.search();
  },
  methods: {
    //查询
    async search() {
      try {
        let res = await client.callApi("frag/List",{cur:this.cur,key:this.keywords});
        if (res.isSucc) {
          this.tableData = [...res.res.list];
          this.count = res.res.count;
        }
      } catch (error) {
        console.log(error);
      }
    },

    //翻页
    handleCurrentChange(e: number) {
      this.cur = e;
      this.search();
    },

    toggleSelection(rows: any[]) {
      let refs:any = this.$refs.multipleTable;
      if (rows) {
        rows.forEach((row) => {
          refs.toggleRowSelection(row);
        });
      } else {
        refs.clearSelection();
      }
    },

    handleSelectionChange(val: any[]) {
      this.multipleSelection = val;
    },

    toEdit(e: { id: any; }) {
      let id = e.id;
      this.$router.push({ name: "frag-edit", params: { id: id } });
    },

    //删除
    async handleDel(e: { id: any; }) {
      let id = e.id;
      try {
        let res = await client.callApi("article/Del",{ids:[id]});
        if (res.isSucc) {
          ElMessage({
            message: "文章删除成功 ^_^",
            type: "success",
          });
          this.search();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});
